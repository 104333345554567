import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'default-passive-events'
import echarts from "echarts"
Vue.prototype.$echarts = echarts
import md5 from 'js-md5' // 加密密码
Vue.prototype.$md5 = md5 

Vue.config.productionTip = false //解决控制台警告
Vue.use(ElementUI);

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
