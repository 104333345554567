import request from "@/utils/request"


// import { AXIOSlogin } from '@/api/user'
// async onLogin () {
//   try {
//     const res = await login(this.user)
//     console.log('登录成功', res)
//     // this.$message.success("操作成功!")
//   } catch (err) {
//     // this.$message("操作失败 请重试!")
//   }
// }
/**
 * 用户登录
 */
export const AXIOSlogin = params => {
  return request({
    method: 'POST',
    url: '/platform/DmBaseUser/login',
    params
  })
}
// 修改密码
export const AXIOSupdataPassword = params => {
  return request({
    method: 'POST',
    url: '/platform/DmBaseUser/modifyPassword',
    params
  })
}
// 验证命令
export const AXIOSverifyCommandPassword = params => {
  return request({
    method: 'POST',
    url: '/platform/DmCustomerBaseInfo/getPassWord',
    params
  })
}
// 用户列表
export const AXIOSuserList = params => {
  return request({
    method: 'GET',
    url: '/platform/DmBaseUser/list',
    params
  })
}
// 添加用户
export const AXIOSaddUser = params => {
  return request({
    method: 'POST',
    url: '/platform/DmBaseUser/addUser',
    params
  })
}
// 删除用户
export const AXIOSdelUser = params => {
  return request({
    method: 'POST',
    url: '/platform/DmBaseUser/delUser',
    params
  })
}