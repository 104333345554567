<template>
  <div id="app">
    <!-- <el-button ref="fullscreenButton" v-show="isShow" @click="toggleFullScreen()"></el-button> -->
    <router-view/>
  </div>
</template>

<script>
import './assets/css/base.css'
export default {
  data() {
    return {
      isShow:true
    }
  },
  
  mounted() {
    // this.simulateButtonClick()
  },
  methods: {
    toggleFullScreen() {
      const doc = window.document;
      const docEl = doc.documentElement;

      const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
      const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        // 进入全屏
        if (requestFullScreen) {
          requestFullScreen.call(docEl);
        }
      } else {
        // 退出全屏
        // if (cancelFullScreen) {
        //   cancelFullScreen.call(doc);
        // }
      }
    },
    // simulateButtonClick() {
    //   // 获取按钮引用并触发点击事件
    //   this.$refs.fullscreenButton.$el.click();
    // },
  },
}
</script>
<style lang="less" scoped>
#app {
  
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
  margin-right: calc(100% - 100vw); //出现纵向滚动条时 不会引起窗口抖动

}
// .routerView{
//   min-width: 1200px !important;
// }
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
